import React, { useMemo } from 'react';
import { GitSquare } from './GitSquare';

interface GitColumnProps {
  transform?: string;
}

export const ROWS_NUMBER = 7;

export const GitColumn = ({ transform }: GitColumnProps) => {
  const rows = useMemo(() => {
    const result = [];
    for (let i = 0; i < ROWS_NUMBER; i += 1) {
      result.push(<GitSquare key={i} y={i * 15} />);
    }
    return result;
  }, []);
  return <g transform={transform}>{rows}</g>;
};
