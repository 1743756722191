import { makeStyles, SvgIcon } from '@material-ui/core';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { GitColumn, ROWS_NUMBER } from './GitColumn';

const height = ROWS_NUMBER * 15;

const useWindowWidth = () => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth - 64 : 480
  );

  const onResize = useCallback(() => {
    setWidth(typeof window !== 'undefined' ? window.innerWidth - 64 : 480);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      window.addEventListener('load', onResize);
    }
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('load', onResize);
    };
  });
  return width - 32;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    borderRadius: 10,
    border: '1px solid #30363d',
    width: ({ width }: { width: number; svgWidth: number }) => width,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    height,
    width: ({ svgWidth }: { width: number; svgWidth: number }) => svgWidth,
    backgroundColor: '#0D1117',
  },
  placeholder: {
    height,
  },
}));

export const GitContribution = () => {
  const width = useWindowWidth();
  const columnNumber = useMemo(() => Math.floor((width - 32) / 15), [width]);
  const svgWidth = useMemo(() => columnNumber * 15, [columnNumber]);
  const viewBox = useMemo(
    () => `0 0 ${columnNumber * 15} ${ROWS_NUMBER * 15}`,
    [columnNumber]
  );
  const [isClient, setIsClient] = useState(false);
  const styles = useStyles({ width, svgWidth });
  const columns = useMemo(() => {
    const result = [];
    for (let i = 0; i < columnNumber; i += 1) {
      result.push(<GitColumn key={i} transform={`translate(${i * 16}, 0)`} />);
    }
    return result;
  }, [columnNumber]);

  //gatsby assumes server and first client render are identical :(
  useEffect(() => {
    setIsClient(true);
  }, []);

  const result = useMemo(
    () => (
      <div className={styles.wrapper}>
        <SvgIcon className={styles.root} viewBox={viewBox}>
          {columns}
        </SvgIcon>
      </div>
    ),
    [viewBox, styles, columns, width]
  );

  return isClient ? result : <div className={styles.placeholder} />;
};
