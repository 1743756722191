import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FrameworksLogos } from '../components/FrameworksLogos/FrameworksLogos';
import { GitContribution } from '../components/GitContribution/GitContribution';
import { Badges } from '../components/Badges/Badges';

import { Helmet } from 'react-helmet';

import '../styles/main.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#0D1117',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

const IndexPage = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Helmet title="Vasilii Sorokin" />
      <GitContribution />
      <Badges />
      <FrameworksLogos />
    </div>
  );
};

export default IndexPage;
