import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { randomInteger } from '../../helpers/randomInteger';

interface GitSquareProps {
  y?: number;
}

const colors: { [k: number]: { fill: string; outline: string } } = {
  0: {
    fill: '#161b22',
    outline: 'rgba(27,31,35,0.06)',
  },
  1: {
    fill: '#0e4429',
    outline: 'hsla(0,0%,100%,0.05)',
  },
  2: {
    fill: '#006d32',
    outline: 'hsla(0,0%,100%,0.05)',
  },
  3: {
    fill: '#26a641',
    outline: 'hsla(0,0%,100%,0.05)',
  },
  4: {
    fill: '#39d353',
    outline: 'hsla(0,0%,100%,0.05)',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 11,
    height: 11,
    fill: ({ level }: { level: number }) => colors[level]?.fill,
    outline: ({ level }: { level: number }) => colors[level]?.outline,
    outlineOffset: -1,
  },
}));

export const GitSquare = ({ y }: GitSquareProps) => {
  const [level, setLevel] = useState(randomInteger(0, 4));
  const styles = useStyles({ level });
  useEffect(() => {
    const interval = setInterval(() => {
      setLevel(randomInteger(0, 4));
    }, randomInteger(500, 2500));
    return () => {
      clearInterval(interval);
    };
  });
  return <rect className={styles.root} rx="2" ry="2" y={y}></rect>;
};
