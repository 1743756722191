import React from 'react';
import { makeStyles } from '@material-ui/core';
import ApolloSVG from '../../images/frameworks/apollo.svg';
import MaterialUISVG from '../../images/frameworks/material-ui.svg';
import ReactSVG from '../../images/frameworks/react-icon.svg';
import TypeScriptSVG from '../../images/frameworks/typescript.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: theme.spacing(2.5),
    width: 70,
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

const FRAMEWORKS = [
  {
    ImgComponent: TypeScriptSVG,
    alt: 'TypeScript',
    link: 'https://www.typescriptlang.org',
  },
  {
    ImgComponent: ReactSVG,
    alt: 'React.js',
    link: 'https://reactjs.org',
  },
  {
    ImgComponent: ApolloSVG,
    alt: 'Apollo',
    link: 'https://www.apollographql.com',
  },
  {
    ImgComponent: MaterialUISVG,
    alt: 'MaterialUI',
    link: 'https://mui.com',
  },
];

export const FrameworksLogos = () => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      {FRAMEWORKS.map(({ link, ImgComponent, alt }) => (
        <a href={link} target="_blank" key={alt}>
          <ImgComponent className={styles.icon} alt={alt} />
        </a>
      ))}
    </div>
  );
};
