import React from 'react';

export const Badges = () => {
  return (
    <div>
      <a href="https://www.codewars.com/users/vasiliisorokin" target="_blank">
        <img
          src="https://www.codewars.com/users/vasiliisorokin/badges/large"
          alt="CodeWars"
        />
      </a>
    </div>
  );
};
